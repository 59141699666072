import './App.css';
import CurveSolverMainScene from './CurveSolver/CurveSolverMainScene';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Auth/Login';
import ProtectedRoute from './Shared/ProtectedRoute';
import CreateValidatorTheme from './Shared/Design/CreateValidatorTheme';
import ProjectsScene from './Projects/ProjectsScene';
import TiltValidationScene from './TiltValidator/TiltValidationScene';
// import { LicenseInfo } from '@mui/x-data-grid';
import ValidatorsMainScene from './Validators/ValidatorsMainscene';
import CurveSolverExternalImporter from './CurveSolver/CurveSolverExternalImporter';
import SlopePlugin from './TiltValidator/SlopePlugin';
import ProjectDetailScene from './Projects/ProjectDetailScene';
import AdminScene from './Users/AdminScene';
import UserScene from './Users/UserScene';
import BimValidatorFrame from './Validators/BimValidatorFrame';
import UpdaterScene from './Updater/UpdaterScene';
import CurveSolverHelpScene from './CurveSolver/CurveSolverHelpScene';
import UserSignupScene from './Users/UserSignupScene';
import UserResetPasswordScene from './Users/UserResetPasswordScene';
import EtcsDataMainScene from './ETCS/EtcsDataMainScene';
import RedirectHandler from './Auth/RedirectHandler';
import ToolLogin from './Auth/ToolLogin';
import PWLoginScene from './ProjectWise/PWLoginScene';
import PWMainScene from './ProjectWise/PWMainScene';
import { PwUserProvider } from './ProjectWise/Context/PWUserDataProvider';
import LoginToolGateRedirect from './Routes/LoginToolGateRedirect';
import ProtectedToolRoute from './Shared/Protected/ProtectedToolRoute';

function App() {
  // LicenseInfo.setLicenseKey('cea41abee7db7cb80eeb1a77ddaeaef8Tz03MzM5NixFPTE3MjQ1MjM4OTAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
  /* const curveSolverTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 'bold', // Set the font weight to 'bold' for all buttons
          },
        },
      },
    },
  });
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/tilt",
      element: <TiltValidationScene />,
    },
    {
      path: "/tilt",
      element: <TiltValidationScene />,
    },
    {
      path: "/solverMain",
      element: <ProtectedRoute><CurveSolverMainScene /></ProtectedRoute>,
    },
  ]); */
  const theme = CreateValidatorTheme();
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {/* <RouterProvider router={router} /> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="gateredirect/:redirect/:lang/:session" element={<LoginToolGateRedirect />} />
            <Route path="/updates" element={<UpdaterScene />} />
            <Route path="/pwlogin" element={<PWLoginScene />} />
            <Route path="/pwmain" element={<PwUserProvider><PWMainScene /></PwUserProvider>} />

            <Route path="/login/:redirect" element={<Login />} />
            {/* <Route path="validators" element={<ProtectedRoute><ValidatorsMainScene /></ProtectedRoute>} /> */}
            <Route path="projects" element={<ProtectedToolRoute><ProjectsScene /></ProtectedToolRoute>} />
            <Route path="projects-slope" element={<ProtectedToolRoute><ProjectsScene onlySlope /></ProtectedToolRoute>} />
            <Route path="projects-curve" element={<ProtectedToolRoute><ProjectsScene onlyCurve /></ProtectedToolRoute>} />
            <Route path="routes/:projectId" element={<ProtectedToolRoute><ProjectDetailScene /></ProtectedToolRoute>} />
            <Route path="solverMain/:id?" element={<ProtectedToolRoute><CurveSolverMainScene /></ProtectedToolRoute>} />
            <Route path="slopeguard" element={<ProtectedToolRoute><TiltValidationScene /></ProtectedToolRoute>} />
            <Route path="curveimport" element={<ProtectedToolRoute><CurveSolverExternalImporter /></ProtectedToolRoute>} />
            <Route path="slopeplugin" element={<SlopePlugin  />} />
            <Route path="slopeplugin/logged" element={<SlopePlugin isLogged />} />
            <Route path="slopevalidation/:id" element={<ProtectedToolRoute><TiltValidationScene /></ProtectedToolRoute>} />

            {/*  */}
            <Route path="help" element={<CurveSolverHelpScene />} ></Route>
            {/* <Route path="usersignup" element={<UserSignupScene />} />
             */}
            <Route path="auth-callback/" element={<RedirectHandler />} />
            <Route path="auth-callback/:hash" element={<RedirectHandler />} />
            <Route path="etcs" element={<EtcsDataMainScene />} />
            <Route path="tool-login" element={<ToolLogin />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
